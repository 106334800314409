@import './reset.scss';
@import './variables.scss';

.signup {
  border: 4px solid $highlight-blue;
  border-radius: 0.3rem;
  margin: auto;
  width: 60%;
  text-align: center;

  @media (max-width: $mobile) {
    width: 100%;
  }

  h2 {
    color: $strong-blue;
    margin: 2rem 0;
    font-size: 2.5rem;
  }

  p {
    margin: auto;
    color: $med-blue;
    width: 80%;

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
}
