@import '../../styles/reset.scss';
@import '../../styles/variables.scss';

.intro {
  margin: 0 2.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    text-align: center;
  }
}

.img-container {
  width: 20%;
  margin: auto;

  img {
    width: 90%;
    height: 90%;
    border-radius: 15rem;
    margin-bottom: 2rem;
  }
}

.top-content {
  width: 60%;
  margin: auto;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    width: 100%;
    margin-top: 1rem;
  }

  .line1 {
    color: $highlight-blue;
    font-size: 1.7rem;
    text-align: center;
  }
  .line2 {
    color: $strong-blue;
    font-size: 4rem;
    text-align: center;
  }
  .line3 {
    color: $med-blue;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  .line4 {
    color: $med-blue;
    font-size: 1rem;
    width: 80%;
    margin: auto;
    text-align: center;

    @media (max-width: $mobile) {
      width: 100%;
    }

    a {
      text-decoration-color: $highlight-blue;
      color: $med-blue;
    }
  }
}

.archives-redirect {
  text-decoration: none;

  .archives-text {
    color: $med-blue;
    margin-top: 2rem;
    text-align: center;
  }
}

.social {
  display: flex;
  margin: auto;
  padding-top: 2rem;

  @media (max-width: $mobile) {
    justify-content: center;
  }

  li {
    font-size: 2rem;
    margin-right: 0.5rem;

    .icon {
      color: $highlight-blue;
    }
  }
}
