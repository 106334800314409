@import url(https://fonts.googleapis.com/css?family=Yantramanav&display=swap);
@import url(https://fonts.googleapis.com/css?family=Yantramanav&display=swap);
@import url(https://fonts.googleapis.com/css?family=Yantramanav&display=swap);
@import url(https://fonts.googleapis.com/css?family=Yantramanav&display=swap);
@import url(https://fonts.googleapis.com/css?family=Yantramanav&display=swap);
@import url(https://fonts.googleapis.com/css?family=Yantramanav&display=swap);
@import url(https://fonts.googleapis.com/css?family=Yantramanav&display=swap);
@import url(https://fonts.googleapis.com/css?family=Yantramanav&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  font-family: 'Yantramanav', sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  height: 100%; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.nav-bar {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;
  margin: 0 2.5rem; }
  .nav-bar .item {
    margin-right: 1rem;
    color: #226597;
    text-decoration: none; }
    .nav-bar .item:hover {
      color: #87c0cd; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  font-family: 'Yantramanav', sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  height: 100%; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.signup {
  border: 4px solid #87c0cd;
  border-radius: 0.3rem;
  margin: auto;
  width: 60%;
  text-align: center; }
  @media (max-width: 500px) {
    .signup {
      width: 100%; } }
  .signup h2 {
    color: #204969;
    margin: 2rem 0;
    font-size: 2.5rem; }
  .signup p {
    margin: auto;
    color: #226597;
    width: 80%; }
    .signup p:last-of-type {
      margin-bottom: 2rem; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  font-family: 'Yantramanav', sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  height: 100%; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.intro {
  margin: 0 2.5rem;
  height: 100%;
  display: flex;
  flex-direction: column; }
  @media (max-width: 500px) {
    .intro {
      text-align: center; } }

.img-container {
  width: 20%;
  margin: auto; }
  .img-container img {
    width: 90%;
    height: 90%;
    border-radius: 15rem;
    margin-bottom: 2rem; }

.top-content {
  width: 60%;
  margin: auto;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column; }
  @media (max-width: 500px) {
    .top-content {
      width: 100%;
      margin-top: 1rem; } }
  .top-content .line1 {
    color: #87c0cd;
    font-size: 1.7rem;
    text-align: center; }
  .top-content .line2 {
    color: #204969;
    font-size: 4rem;
    text-align: center; }
  .top-content .line3 {
    color: #226597;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center; }
  .top-content .line4 {
    color: #226597;
    font-size: 1rem;
    width: 80%;
    margin: auto;
    text-align: center; }
    @media (max-width: 500px) {
      .top-content .line4 {
        width: 100%; } }
    .top-content .line4 a {
      -webkit-text-decoration-color: #87c0cd;
              text-decoration-color: #87c0cd;
      color: #226597; }

.archives-redirect {
  text-decoration: none; }
  .archives-redirect .archives-text {
    color: #226597;
    margin-top: 2rem;
    text-align: center; }

.social {
  display: flex;
  margin: auto;
  padding-top: 2rem; }
  @media (max-width: 500px) {
    .social {
      justify-content: center; } }
  .social li {
    font-size: 2rem;
    margin-right: 0.5rem; }
    .social li .icon {
      color: #87c0cd; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  font-family: 'Yantramanav', sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  height: 100%; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.header {
  text-align: center;
  margin: 2rem 2.5rem; }
  .header .page-header {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    color: #204969; }

hr {
  border: 1px solid #204969;
  margin-bottom: 2rem; }

.post-container {
  margin: auto;
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  padding: 5rem;
  justify-content: space-between; }
  @media (max-width: 500px) {
    .post-container {
      width: 100%; } }
  .post-container .post {
    border: 1px solid #226597;
    border-radius: 0.3rem;
    box-shadow: 4px 2px 6px #87c0cd;
    width: 45%;
    padding: 1rem;
    text-align: center;
    color: #204969;
    margin: auto;
    text-decoration: none; }
    @media (max-width: 500px) {
      .post-container .post {
        width: 80%; } }
    .post-container .post a {
      font-weight: bold;
      color: #204969; }
    .post-container .post p {
      margin-top: 1rem;
      color: #226597; }

.post-border {
  margin: 2rem 0; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  font-family: 'Yantramanav', sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  height: 100%; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.article-wrapper {
  padding: 2.9rem; }

.article-container {
  width: 70%;
  margin: auto; }
  @media (max-width: 500px) {
    .article-container {
      width: 100%; } }
  .article-container .title {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 3rem;
    color: #204969; }
  .article-container .date {
    text-align: center;
    margin-bottom: 2rem; }
  .article-container p {
    margin-bottom: 1rem;
    color: #226597; }
  .article-container a {
    color: #226597; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  font-family: 'Yantramanav', sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  height: 100%; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.bio-header {
  text-align: center;
  color: #204969;
  font-size: 2rem; }

.about-details {
  margin: auto;
  width: 50%;
  padding: 3rem;
  color: #226597; }
  @media (max-width: 500px) {
    .about-details {
      width: 100%; } }
  .about-details a {
    color: #87c0cd; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  font-family: 'Yantramanav', sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  height: 100%; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.section-header {
  margin: 2rem 2.5rem; }
  .section-header .section-title {
    font-size: 1rem;
    color: #204969; }

.featured-container {
  padding: 0 2.5rem; }
  .featured-container .featured {
    margin-bottom: 1.7rem; }

.desc {
  display: flex;
  width: 60%; }
  .desc .title {
    font-size: 1.5rem;
    font-weight: bolder;
    color: #204969;
    -webkit-text-decoration-line: none;
            text-decoration-line: none; }
    .desc .title:hover, .desc .title:focus {
      -webkit-transform: translateY(-0.25em);
              transform: translateY(-0.25em);
      color: #87c0cd; }
  .desc p {
    align-self: center;
    margin-left: auto;
    color: #226597; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 62.5%;
  font: inherit;
  font-family: 'Yantramanav', sans-serif;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  height: 100%; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

.content {
  margin: 0 2.5rem;
  display: flex;
  flex-direction: column; }
  .content h2 {
    color: #226597;
    font-size: 1.3rem;
    margin-bottom: 1.4rem;
    text-align: center; }
  .content .archive {
    font-size: 1rem;
    font-weight: bolder;
    color: #204969;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    display: block;
    margin-bottom: 1rem;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
    .content .archive:hover, .content .archive:focus {
      -webkit-transform: translateY(-0.25em);
              transform: translateY(-0.25em);
      color: #87c0cd; }

