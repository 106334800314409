@import '../../styles/reset.scss';
@import '../../styles/variables.scss';

.article-wrapper {
  padding: 2.9rem;
}

.article-container {
  width: 70%;
  margin: auto;

  @media (max-width: $mobile) {
    width: 100%;
  }

  .title {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 3rem;
    color: $strong-blue;
  }

  .date {
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 1rem;
    color: $med-blue;
  }

  a {
    color: $med-blue;
  }
}
