@import '../../styles/reset.scss';
@import '../../styles/variables.scss';

.bio-header {
  text-align: center;
  color: $strong-blue;
  font-size: 2rem;
}

.about-details {
  margin: auto;
  width: 50%;
  padding: 3rem;
  color: $med-blue;

  @media (max-width: $mobile) {
    width: 100%;
  }
  a {
    color: $highlight-blue;
  }
}
