@import './reset.scss';

.nav-bar {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0;
  margin: 0 2.5rem;

  .item {
    margin-right: 1rem;
    color: #226597;
    text-decoration: none;

    &:hover {
      color: #87c0cd;
    }
  }
}
