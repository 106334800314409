@import '../../styles/reset.scss';
@import '../../styles/variables.scss';

.section-header {
  margin: 2rem 2.5rem;
  .section-title {
    font-size: 1rem;
    color: $strong-blue;
  }
}

.featured-container {
  padding: 0 2.5rem;

  .featured {
    margin-bottom: 1.7rem;
  }
}

.desc {
  display: flex;
  width: 60%;

  .title {
    font-size: 1.5rem;
    font-weight: bolder;
    color: $strong-blue;
    text-decoration-line: none;

    &:hover,
    &:focus {
      transform: translateY(-0.25em);
      color: $highlight-blue;
    }
  }

  p {
    align-self: center;
    margin-left: auto;
    color: $med-blue;
  }
}
